import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  add,
  sub,
  format,
  isEqual,
  eachMonthOfInterval,
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  startOfDay,
  startOfWeek,
} from "date-fns";
import de from "date-fns/locale/de";
import Splide from "@splidejs/splide/dist/js/splide.esm";

const TOTAL_PREGNANCY_DAYS = 280;

let currentVersion = null;

window.onload = async () => {
  render();
  initializeSwiper();
  currentVersion = await getLatestVersion();
};
window.onfocus = async () => {
  render();
  await update();
};

function render() {
  const dateStart = new Date("2020-11-30");
  document.querySelector("#dateStart").textContent = format(dateStart, "PPPP", {
    locale: de,
  });

  const dateEnd = add(dateStart, { days: TOTAL_PREGNANCY_DAYS });
  document.querySelector("#dateEnd").textContent = format(dateEnd, "PPPP", {
    locale: de,
  });

  const now = new Date();

  const daysOfPregnancy = differenceInCalendarDays(
    now,
    sub(dateStart, { days: 1 })
  );
  document.querySelector("#daysOfPregnancy").textContent = daysOfPregnancy;

  const pregnancyWeekExact = Math.floor((daysOfPregnancy - 1) / 7) + 1;
  document.querySelector(
    "#pregnancyWeekExact"
  ).textContent = pregnancyWeekExact;

  const pregnancyWeekDayExact = daysOfPregnancy - 7 * (pregnancyWeekExact - 1);
  document.querySelector(
    "#pregnancyWeekDayExact"
  ).textContent = pregnancyWeekDayExact;

  const pregnancyPercentage = Math.round(
    (daysOfPregnancy / TOTAL_PREGNANCY_DAYS) * 100
  );
  document.querySelector(
    "#pregnancyPercentage progress"
  ).value = pregnancyPercentage;
  document.querySelector(
    "#pregnancyPercentage small"
  ).textContent = `${pregnancyPercentage}%`;

  const daysUntilBirth = differenceInCalendarDays(dateEnd, now);
  document.querySelector("#daysUntilBirth").textContent = daysUntilBirth;

  renderCalendar(dateStart, dateEnd, dateStart);
}

/**
 *
 * @param start Date
 * @param end Date
 * @returns void
 */
function renderCalendar(start, end) {
  const now = new Date();
  const months = eachMonthOfInterval({
    start,
    end,
  });

  let nextWeeklyBadge = { counter: 1, date: startOfDay(start) };
  let content = "";
  let i = 0;
  for (const month of months) {
    let monthNumber = "";

    if (i >= 1 && i <= 9) {
      monthNumber = ` <small style="font-size: small">(${i}. Monat)</small>`;
    }

    content += `<div class="calendar-sheet">
    <h3>${format(month, "MMMM", {
      locale: de,
    })}${monthNumber}</h3>
    <div class="calendar-month">
    <div>Mo</div>
    <div>Di</div>
    <div>Mi</div>
    <div>Do</div>
    <div>Fr</div>
    <div>Sa</div>
    <div>So</div>`;
    content += `<div></div>`.repeat(
      differenceInCalendarDays(month, startOfWeek(month, { locale: de }))
    );
    for (const day of eachDayOfInterval({
      start: startOfMonth(month),
      end: endOfMonth(month),
    })) {
      const isToday = isEqual(startOfDay(now), startOfDay(day));

      if (
        nextWeeklyBadge.date < end &&
        isEqual(startOfDay(day), nextWeeklyBadge.date)
      ) {
        content += `<div class="badge-container ${isToday ? "today" : ""}">
          <div>${day.getDate()}</div>
          <div class="badge">${
            isEqual(startOfDay(day), startOfDay(end))
              ? "👶"
              : nextWeeklyBadge.counter
          }</div>
        </div>`;
        nextWeeklyBadge = {
          counter: nextWeeklyBadge.counter + 1,
          date: startOfDay(add(nextWeeklyBadge.date, { days: 7 })),
        };
        continue;
      }
      content += `<div ${
        isToday ? `class="today"` : ""
      }>${day.getDate()}</div>`;
    }
    content += `</div></div>`;
    i++;
  }
  document.querySelector("#calendar").innerHTML = content;
  const currentMonth = document.querySelector(
    `#calendar .calendar-sheet:nth-child(${
      differenceInCalendarMonths(endOfMonth(now), startOfMonth(start)) +
      1
    })`
  );
  document.querySelector("#calendar").style.height =
    currentMonth.offsetHeight + 12 + "px";
  document.querySelector("#calendar").scrollTop = currentMonth.offsetTop;
}

async function update() {
  if (currentVersion === null) {
    return;
  }

  const latestVersion = await getLatestVersion();
  if (isEqual(currentVersion, latestVersion)) {
    return;
  }

  window.location.reload();
}

async function getLatestVersion() {
  try {
    const response = await fetch("version.json");
    const version = await response.json();

    return new Date(version.date);
  } catch (e) {
    console.error("getLatestVersion", e);
  }
}

function initializeSwiper() {
  new Splide("#image-slider", {
    width: "400px",
    heightRatio: 0.75,
    cover: true,
    type: "loop",
  }).mount();
}
